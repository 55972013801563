<template>
    <div>
        <v-app
            id="inspire"
            class="ventesapp"
        >
            <v-layout
                class="deviceContainer"
                column
                fill-height
            >
                <header>
                    <div class="gradient" />
                    <v-app-bar
                        color="black"
                        dark
                    >
                        <v-btn
                            icon
                            to="/"
                        >
                            <v-icon>arrow_back</v-icon>
                        </v-btn>
                        <h2>
                            {{ $t("devicepage.title") }}
                        </h2>
                    </v-app-bar>
                </header>
                <v-list>
                    <v-list-item>
                        <v-text-field
                            v-if="pinsecured"
                            v-model="settings.apiDeviceKey"
                            :label="$t('devicepage.device')"
                            clearable
                            @change="saveToLocalStorage"
                        />
                    </v-list-item>
                    <v-container
                        container--fluid
                        grid-list-md
                    >
                        <v-btn
                            v-if="pinsecured"
                            @click="newUuidDialog = true"
                        >
                            {{ $t("devicepage.renew") }}
                        </v-btn>
                        <v-dialog
                            v-model="newUuidDialog"
                            persistent
                            max-width="300"
                        >
                            <v-card>
                                <v-card-text
                                    center
                                >
                                    <h1>
                                        {{ $t("devicepage.dialog") }}
                                    </h1>
                                    <h2>
                                        {{ $t("devicepage.sure") }}
                                    </h2>
                                </v-card-text>
                                <v-card-text>
                                    <v-btn
                                        color="green darken-1"
                                        width="120"
                                        @click.native="agree()"
                                    >
                                        <icon
                                            name="fa/solid/check"
                                            class="barIcon"
                                            color="white"
                                        />
                                    </v-btn>
                                    <v-btn
                                        color="red darken-1"
                                        width="130"
                                        @click.native="newUuidDialog = false"
                                    >
                                        <icon
                                            name="fa/solid/times"
                                            class="barIcon"
                                            color="white"
                                        />
                                    </v-btn>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-container>
                </v-list>
                <pinDialog ref="pinCodeDialog"/>
            </v-layout>
        </v-app>
    </div>
</template>
<script>
import pinDialog from '../components/pinDialog'
import uuidv4 from 'uuid/v4'
import Icon, { register as registerIcons } from '@wisol/icons'
import CheckIcon from '@wisol/icons/src/icons/fa/solid/check'
import TimesIcon from '@wisol/icons/src/icons/fa/solid/times'

registerIcons(CheckIcon, TimesIcon)

export default {
    name: 'Device',
    components: {
        pinDialog,
        Icon
    },
    data () {
        return {
            disableGoBack: false,
            pinsecured: false,
            newUuidDialog: false,
            settings: {}
        }
    },
    created () {
        window.addEventListener('beforeunload', this.saveToLocalStorage)
        this.loadFromLocalStorage()
        this.DeviceUuid()
    },
    mounted () {
        this.openPinDialog()
    },
    beforeDestroy () {
        window.removeEventListener('beforeunload', this.saveToLocalStorage)
        this.saveToLocalStorage()
    },
    methods: {
        openPinDialog () {
            const pinDialog = this.$refs.pinCodeDialog
                .open(
                    this.settings.pincode
                )
                .then(() => {
                    this.pinsecured = true
                })
            return pinDialog
        },
        agree () {
            // set new uuidv4 key
            this.settings.apiDeviceKey = uuidv4()
            this.newUuidDialog = false
        },
        DeviceUuid () {
            // create DeviceKey
            if (!this.settings.apiDeviceKey) {
                this.settings.apiDeviceKey = uuidv4()
            }
        },
        saveToLocalStorage () {
            // save to localStorage
            localStorage.setItem('settings', JSON.stringify(this.settings))
        },
        loadFromLocalStorage () {
            // load from localStorage
            const settings = localStorage.getItem('settings')
            if (settings) {
                this.settings = {
                    ...this.settings,
                    ...JSON.parse(settings)
                }
            }
        }
    }
}
</script>
<style>
.gradient {
  background: linear-gradient(90deg, #CC6633, #B9131D, #781345, #442254);
  padding-bottom: 10px;
}
.marg-left-10 {
    margin-left: 10px;
}
.deviceContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
.deviceContainer header {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
</style>
